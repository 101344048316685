import React from 'react'

const BooksList = () => {
  return (
    <div>
      <h2>My Books List</h2>
      <h3>Current</h3>
      <h3>Finished</h3>
    </div>
  )
}

export default BooksList
